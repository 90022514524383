import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Parallax } from 'react-parallax';

import { Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';
import Carousel from '../components/Carousel';
import CardWide from '../components/Cards/CardWide';
import CardHorizontal from '../components/Cards/CardHorizontal';
import SEO from '../components/SEO';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    padding: theme.spacing(3, 2),
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      listStyleType: 'decimal',
      '& li': {
        alignItems: 'center',
        '& p': {
          float: 'left',
          paddingLeft: '8px',
        },
      },
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#1b8ecf',
    border: '1px solid transparent',
    '& a': {
      color: 'white',
      textDecoration: 'none',
      textShadow: '1px 1px 5px #000',
    },
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #1b8ecf',
      '& a': {
        color: '#1b8ecf',
        textShadow: 'none',
      },
    },
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 110);

export const query = graphql`
  query {
    mobileImage1: file(relativePath: { eq: "loodgieter/loodgieter-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage1: file(relativePath: { eq: "loodgieter/loodgieter-6.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileImage2: file(relativePath: { eq: "bathroom/bathroom-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage2: file(relativePath: { eq: "bathroom/bathroom-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileImage3: file(relativePath: { eq: "bathroom/bathroom-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage3: file(relativePath: { eq: "bathroom/bathroom-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileCard: file(relativePath: { eq: "bathroom/Afbeelding 1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const Home = ({ data, path, location }) => {
  const classes = useStyles();
  const esi = useRef(null);
  const executeScroll = () => scrollToRef(esi);

  const sources1 = [
    data.mobileImage1.childImageSharp.fluid,
    {
      ...data.desktopImage1.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];
  const sources2 = [
    data.mobileImage2.childImageSharp.fluid,
    {
      ...data.desktopImage2.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];
  const sources3 = [
    data.mobileImage3.childImageSharp.fluid,
    {
      ...data.desktopImage3.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const images = [sources1, sources2, sources3];

  const seo = {
    title: 'Welkom',
    description: `E S I - Installatietechniek B.V. verzorgt alle installatie in en rondom uw huis. Heeft u problemen of zoekt u naar hulp? Bij E S I - Installatietechniek B.V. bent u aan het juiste adres.`,
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={path}
        location={location}
      />
      <div className='carousel'>
        <Carousel images={images} />
        <div className='carousel__text'>
          <h1>E S I - Installatietechniek B.V.</h1>

          <div className='hidden-sm hidden-md hidden-lg'>
            <a href='tel:0642090304'>
              <Button
                role='button'
                variant='contained'
                className='btn heading__button'
              >
                <i className='material-icons mr-1x'>phone</i>Krijg advies op
                maat
              </Button>
            </a>
          </div>
          <AniLink fade to='/contact' className='hidden-xs'>
            <Button
              role='button'
              variant='contained'
              className='btn heading__button btn--small'
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </AniLink>
        </div>
        <button
          type='button'
          href='#'
          onClick={executeScroll}
          className='carousel__arrows'
        >
          <i className='material-icons'>keyboard_arrow_down</i>
          <i className='material-icons'>keyboard_arrow_down</i>
        </button>
      </div>
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle='E S I - Installatietechniek B.V.'
          ref={esi}
        >
          <>
            <p>
              E S I - Installatietechniek B.V. is actief als installateur in de
              omgeving van Purmerend, Waterland, Amsterdam, Zaanstad, en Hoorn.
              Met ruim 18 jaar ervaring op installatiegebied is E S I het juiste
              adres voor klantgerichte service, uitstekende adviezen en zeer
              vakkundig installatiewerk.
            </p>
            <p>
              Onze medewerkers zetten zich iedere dag voor de volle 100% in voor
              een tevreden klant. We bieden een veelomvattend dienstenpakket
              zodat u bijna iedere installatieklus aan onze specialisten kunt
              uitbesteden. Onze installateurs ontzorgen u op het gebied van
              badkamers, leidingwerk, riolering, gas- en waterinstallaties,
              centrale verwarming, ventilatie en duurzame oplossingen.
            </p>
          </>
        </CardWide>
        <CardHorizontal
          img={data.mobileCard.childImageSharp.fluid}
          imgClass={classNames(classes.img, 'img--pos img')}
          imgAlt='E S I - Installatietechniek B.V.'
          imgTitle='E S I - Installatietechniek B.V.'
          containerClass='mt-10x cards'
        >
          <div className='mt-md-0 pl-md-4x'>
            <h3>Wat doet E S I - Installatietechniek B.V.</h3>
            <p className='text--white'>
              E S I - Installatietechniek B.V. verzorgt installatiewerkzaamheden
              voor nieuwbouw- en renovatieprojecten bij onder andere:
            </p>
            <ul>
              <li>Particulieren </li>
              <li>Kantoorgebouwen en utiliteit </li>
              <li>Scholen </li>
              <li>Winkelcentra </li>
              <li>Garages </li>
              <li>Sportaccommodaties </li>
              <li>Horeca </li>
              <li>Gezondheidszorg</li>
            </ul>
          </div>
        </CardHorizontal>
      </Container>
      <Parallax
        bgImage={sources3[0].src}
        bgImageAlt='E S I - Installatietechniek B.V.'
        strength={600}
        bgImageStyle={{ filter: 'brightness(0.5)!important' }}
        bgClassName='parallax__image'
      >
        <div className='parallax'>
          <div className='parallax__element'>
            <i className='material-icons'>build</i>
            <h3>Vakmannen</h3>
          </div>
          <div className='parallax__element'>
            <i className='material-icons'>school</i>
            <h3>Hoge kwaliteit</h3>
          </div>
          <div className='parallax__element'>
            <i className='material-icons'>emoji_objects</i>
            <h3>Slimme oplossingen</h3>
          </div>
        </div>
        <div style={{ height: '400px' }} />
      </Parallax>
      <div className='pt-10x pb-10x bg--white'>
        <Container fixed>
          <CardHorizontal
            img={sources2}
            imgClass='img--pos img'
            imgAlt='E S I - Installatietechniek B.V.'
            imgTitle='E S I - Installatietechniek B.V.'
            containerClass='cards'
            textFirst
          >
            <div className='mt-md-0 pl-md-4x'>
              <h3 className='text--brand'>Kwaliteit</h3>
              <p className='text--brand'>
                Kwaliteitszorg en service zijn onlosmakelijk verbonden met
                installeren, dat is dan ook wat E S I – Installatietechniek B.V.
                levert.
              </p>
              <p className='text--brand'>
                Of het nu gaat om installeren van een badkamer, centrale
                verwarmingssystemen, luchtbehandelingssystemen of
                regeltechnische installatie; E S I maakt er maatwerk van!
              </p>
              <p className='text--brand'>
                Altijd met evenveel zorg voor kwaliteit en perfectie. Met
                strakke planningen en een uitstekende organisatie. Met de meest
                moderne technieken. Met meer dan 18 jaar ervaring en
                vakmanschap, van opdracht tot oplevering.
              </p>
            </div>
          </CardHorizontal>
        </Container>
      </div>
    </Layout>
  );
};

Home.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Home;
