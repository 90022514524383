import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Carousel = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };
  return (
    <>
      <Helmet>
        <link
          rel='stylesheet'
          type='text/css'
          charset='UTF-8'
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
        />
        <link
          rel='stylesheet'
          type='text/css'
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
        />
      </Helmet>
      <Slider {...settings} className='slider'>
        {images.map((img, i) => (
          <div key={i}>
            <Img
              loading={i === 0 ? null : 'lazy'}
              fluid={img}
              alt='E S I - Installatietechniek B.V.'
            />
          </div>
        ))}
      </Slider>
    </>
  );
};

Carousel.propTypes = {
  images: PropTypes.array,
};

export default Carousel;
